import {
  ActivateDemographicSection,
  CountrySection,
  InterestDrawer,
  PeopleMatchSection,
} from '@/components/molecules';
import { ActivationScore, SegmentInterest } from '@/types';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import { IActivationScore, IDemographic } from '@/interfaces';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ActivationSelect } from '@/components/atoms';
import { ChevronLeft } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import { PageLayout } from '@/components/layouts';
import { useAudienceActivation } from '@/hooks/useAudienceActivation';
import { useTheme } from '@mui/material/styles';

const ActivatePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const theme = useTheme();
  const { loading, error, activation } = useAudienceActivation(id);

  const [selectedActivation, setSelectedActivation] = useState<string>('meta');
  const [activationOptions, setActivationOptions] = useState<string[]>([]);
  const [editedInterests, setEditedInterests] = useState<ActivationScore[]>([]);
  const [topGender, setTopGender] = useState<IDemographic | null>(null);
  const [topAge, setTopAge] = useState<string | null>(null);
  const [topInterests, setTopInterests] = useState<ActivationScore[]>([]);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [interests, setInterests] = useState<ActivationScore[]>([]);
  const [interestGroups, setInterestGroups] = useState<string[]>([]);
  const [interestTypes, setInterestTypes] = useState<string[]>([]);
  const [segmentNames, setSegmentNames] = useState<string[]>([]);
  const [selectedSegment, setSelectedSegment] = useState<string>('');
  const [selectedInterestType, setSelectedInterestType] = useState<string>('');
  const [segmentInterests, setSegmentInterests] = useState<SegmentInterest[]>(
    []
  );
  const [segmentInterestsGroups, setSegmentInterestsGroups] = useState<
    string[]
  >([]);
  const getSegmentInterests = useCallback(
    (activationScore: IActivationScore, segment: string): SegmentInterest[] => {
      const interests = activationScore[selectedActivation];
      const segmentInterests = interests.sort((a, b) => b.index - a.index);

      return [{ group: segment, interests: segmentInterests }];
    },
    [selectedActivation]
  );

  useEffect(() => {
    if (activation) {
      setActivationOptions(Object.keys(activation.score));

      if (activation.segments) {
        setSegmentNames(activation.segments.map((segment) => segment.name));
      }

      const gender = activation.demographics
        .filter((score) => score.type === 'Gender')
        .reduce((prev, current) =>
          prev.penetration > current.penetration ? prev : current
        );
      const age = activation.demographics
        .filter((score) => score.type === 'Age')
        .reduce((prev, current) =>
          prev.index > current.index ? prev : current
        );
      setTopGender(gender);
      setTopAge(age.name);
    }
  }, [activation]);

  useEffect(() => {
    if (activation && selectedActivation) {
      const interests = activation.score[selectedActivation]
        .sort((a, b) => b.index - a.index)
        .slice(0, 15);
      setTopInterests(interests);
      setEditedInterests(interests);
    }
  }, [activation, selectedActivation]);

  useEffect(() => {
    if (activation) {
      if (activation.segments) {
        const activationScore = activation.segments.find(
          (s) => s.name === selectedSegment
        )?.score;

        if (selectedSegment && activationScore) {
          const interests = getSegmentInterests(
            activationScore,
            selectedSegment
          );
          const groups = interests.flatMap((item) =>
            item.interests.map((i) => i.group)
          );

          const types = interests.flatMap((item) =>
            item.interests.map((i) => i.type)
          );

          if (selectedInterestType) {
            const filteredInterests = interests[0].interests.filter(
              (item) => item.type === selectedInterestType
            );

            setSegmentInterests([
              { group: selectedSegment, interests: filteredInterests },
            ]);
            setSegmentInterestsGroups([...new Set(groups)]);
            setInterestTypes([...new Set(types)]);
            return;
          }
          setSegmentInterests(interests);
          setSegmentInterestsGroups([...new Set(groups)]);
          setInterestTypes([...new Set(types)]);
        }
      } else {
        const interests = activation.score[selectedActivation] || [];
        const groups = interests.flatMap((item) => item.group);
        const types = interests.flatMap((item) => item.type);

        if (selectedInterestType) {
          const filteredInterests = interests.filter(
            (item) => item.type === selectedInterestType
          );
          setInterests(filteredInterests);
          setSegmentInterestsGroups([...new Set(groups)]);
          setInterestTypes([...new Set(types)]);
          return;
        }

        setInterests(interests);
        setInterestGroups([...new Set(groups)]);
        setInterestTypes([...new Set(types)]);
      }
    }
  }, [
    activation,
    selectedSegment,
    getSegmentInterests,
    selectedActivation,
    selectedInterestType,
  ]);

  const handleAddInterest = (interest: ActivationScore) => {
    if (!editedInterests.some((item) => item.name === interest.name)) {
      setEditedInterests((prev) => [...prev, interest]);
    }
  };

  const handleSelectDeselectAll = (
    group: string,
    editedInterests: ActivationScore[],
    segmentInterests?: SegmentInterest[],
    interests?: ActivationScore[]
  ) => {
    let groupInterests: ActivationScore[] = [];

    if (segmentInterests) {
      groupInterests = segmentInterests[0].interests.filter(
        (interest) => interest.group === group
      );
    } else if (interests) {
      groupInterests = interests.filter((interest) => interest.group === group);
    }

    const allSelected = groupInterests.every((interest) =>
      editedInterests.some((i) => i.name === interest.name)
    );

    if (allSelected) {
      setEditedInterests((prev) =>
        prev.filter(
          (item) =>
            !groupInterests.some((interest) => interest.name === item.name)
        )
      );
    } else {
      setEditedInterests((prev) => [
        ...prev,
        ...groupInterests.filter(
          (interest) => !prev.some((item) => item.name === interest.name)
        ),
      ]);
    }
  };

  const handleAgeChange = (age: { min: string; max: string }) => {
    const ageString = `${age.min} - ${age.max}`;
    setTopAge(ageString);
  };

  const handleSelectedInterestType = (type: string) => {
    if (type === selectedInterestType) {
      setSelectedInterestType('');
    } else {
      setSelectedInterestType(type);
    }
  };

  const handleRemoveInterest = (interest: ActivationScore) => {
    setEditedInterests((prev) =>
      prev.filter((item) => item.name !== interest.name)
    );
  };

  return (
    <PageLayout>
      <Grid
        container
        spacing={2}
        px={1}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {loading ? (
          <Grid container spacing={2}>
            <Grid xs={2}>
              <Skeleton
                variant="rounded"
                animation="wave"
                width={160}
                height={30}
              />
            </Grid>
            <Grid xs={12}>
              <Skeleton
                variant="text"
                animation="wave"
                width={140}
                height={50}
              />
            </Grid>
          </Grid>
        ) : error ? (
          <Grid xs={12}>
            <Alert
              severity="error"
              sx={{ background: theme.palette.background.paper }}
            >
              <AlertTitle>No Insights Found</AlertTitle>
              No Insights found for this audience. Please check that the
              audience has been built successfully and that audience insights
              are ready to view.
            </Alert>
          </Grid>
        ) : (
          activation && (
            <>
              <Grid xs={12} mb={2}>
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<ChevronLeft />}
                  onClick={() => history.goBack()}
                >
                  Back to Insights
                </Button>
              </Grid>
              <Grid xs={1}>
                <ActivationSelect
                  selectedActivation={selectedActivation}
                  onChange={(value) => setSelectedActivation(value)}
                  options={activationOptions}
                />
              </Grid>
              {selectedActivation && (
                <Grid xs={12}>
                  <Paper elevation={1}>
                    <Box sx={{ p: 2 }}>
                      <Typography variant="h6" fontWeight="bold" gutterBottom>
                        Target Audience Details
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        This is the targeting criteria for your target audience.
                      </Typography>
                    </Box>
                    <Divider />
                    <Grid container p={1}>
                      {topGender && topAge && (
                        <ActivateDemographicSection
                          topGender={topGender}
                          topAge={topAge}
                          onSave={handleAgeChange}
                          topInterests={topInterests}
                        />
                      )}
                      {activation.country_id && activation.country_iso && (
                        <CountrySection countryName={activation.country_name} />
                      )}
                      {activation.score[selectedActivation].length > 0 && (
                        <PeopleMatchSection
                          topInterests={topInterests}
                          editedInterests={editedInterests}
                          onRemoveInterest={(name) =>
                            setEditedInterests((prev) =>
                              prev.filter((item) => item.name !== name)
                            )
                          }
                          onOpenDrawer={() => setOpenDrawer((prev) => !prev)}
                          onSave={() => setTopInterests(editedInterests)}
                        />
                      )}
                    </Grid>
                  </Paper>
                </Grid>
              )}
            </>
          )
        )}
      </Grid>
      <InterestDrawer
        openDrawer={openDrawer}
        segmentNames={segmentNames}
        selectedSegment={selectedSegment}
        selectedInterestType={selectedInterestType}
        segmentInterestsGroups={segmentInterestsGroups}
        segmentInterests={segmentInterests}
        editedInterests={editedInterests}
        interests={interests}
        interestGroups={interestGroups}
        interestTypes={interestTypes}
        onSelectedSegment={(segment) => {
          setSelectedSegment(segment);
        }}
        onSelectedInterestType={handleSelectedInterestType}
        onOpenDrawer={(value) => setOpenDrawer(value)}
        onRemoveInterest={handleRemoveInterest}
        onEditedInterests={(interests) => setEditedInterests(interests)}
        onAddInterest={handleAddInterest}
        onSelectDeselectAll={handleSelectDeselectAll}
      />
    </PageLayout>
  );
};

export default ActivatePage;
