import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import React from 'react';

interface ActivationSelectProps {
  selectedActivation: string;
  onChange: (value: string) => void;
  options: string[];
}

const ActivationSelect: React.FC<ActivationSelectProps> = ({
  selectedActivation,
  onChange,
  options,
}) => {
  return (
    <FormControl size="small" variant="outlined" fullWidth>
      <InputLabel>Activation</InputLabel>
      <Select
        value={selectedActivation}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        label="Activation"
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ActivationSelect;
