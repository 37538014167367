import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { Edit, Female, Male } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';

import { ActivationScore } from '@/types';
import { IDemographic } from '@/interfaces';
import { titleCase } from '@/helpers';

interface ActivateDemographicSectionProps {
  topGender: IDemographic;
  topAge: string;
  topInterests: ActivationScore[];
  onSave: (age: { min: string; max: string }) => void;
}

const ActivateDemographicSection: React.FC<ActivateDemographicSectionProps> = ({
  topGender,
  topAge,
  onSave,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [age, setAge] = useState<{
    min: string;
    max: string;
  }>({
    min: '',
    max: '',
  });

  useEffect(() => {
    setAge({
      min: topAge.split('-')[0].trim(),
      max: topAge.split('-')[1].trim(),
    });
  }, [topAge]);

  const handleAgeChange = (event: SelectChangeEvent<string>) => {
    setAge(
      (prev) =>
        ({
          ...prev,
          [event.target.name]: event.target.value.toString(),
        } as { min: string; max: string })
    );
  };

  const handleSave = () => {
    setEditMode(false);
    onSave(age);
  };

  const handleCancel = () => {
    setAge({
      min: topAge.split('-')[0].trim(),
      max: topAge.split('-')[1].trim(),
    });
    setEditMode(false);
  };

  return (
    <Grid container display="flex" flexDirection="column">
      <Grid display="flex" flexDirection="row" gap={1} p={2}>
        <Grid xs={2}>
          <Box display="flex" gap={1} alignItems="center">
            <Typography variant="body1" fontWeight="bold">
              Demographics
            </Typography>
            <Button
              variant="text"
              size="small"
              startIcon={<Edit />}
              onClick={() => setEditMode(true)}
            >
              Edit
            </Button>
          </Box>
        </Grid>
        {topGender && (
          <Grid xs={1} display="flex" flexDirection="column" gap={1}>
            <Typography variant="body1" fontWeight="bold">
              {titleCase(topGender.name || '')}
            </Typography>
            <Box display="flex" gap={1}>
              {topGender.name === 'male' ? <Male /> : <Female />}
              <Typography variant="body1">
                {titleCase(topGender.name || '')}
              </Typography>
            </Box>
          </Grid>
        )}
        {topAge && (
          <>
            {editMode ? (
              <Grid xs={5} display="flex" flexDirection="column">
                <Typography variant="body1" fontWeight="bold">
                  Age Range
                </Typography>
                <Grid
                  container
                  display="flex"
                  flexDirection="row"
                  gap={1}
                  mt={1}
                >
                  <FormControl
                    variant="filled"
                    size="small"
                    sx={{ minWidth: 120 }}
                  >
                    <InputLabel>Minimum Age</InputLabel>
                    <Select
                      name="min"
                      value={age?.min}
                      onChange={handleAgeChange}
                      MenuProps={{ sx: { maxHeight: 300 } }}
                    >
                      {Array.from({ length: 100 }, (_, i) => i + 1).map(
                        (item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="filled"
                    size="small"
                    sx={{ minWidth: 120 }}
                  >
                    <InputLabel>Maximum Age</InputLabel>
                    <Select
                      name="max"
                      value={age?.max}
                      onChange={handleAgeChange}
                      MenuProps={{ sx: { maxHeight: 300 } }}
                    >
                      {Array.from({ length: 100 }, (_, i) => i + 1).map(
                        (item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Box display="flex" gap={1} mt={2}>
                  <Button variant="contained" size="small" onClick={handleSave}>
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            ) : (
              <Grid xs={1} display="flex" flexDirection="column" gap={1}>
                <Typography variant="body1" fontWeight="bold">
                  Age Range
                </Typography>
                <Typography variant="body1">{topAge}</Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ActivateDemographicSection;
