import { ActivationScore, SegmentInterest } from '@/types';
import {
  Box,
  Button,
  Chip,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import React from 'react';
import { interestTypeColors } from '@/constants';

interface InterestDrawerProps {
  openDrawer: boolean;
  segmentNames: string[];
  selectedSegment: string;
  selectedInterestType: string;
  segmentInterestsGroups: string[];
  segmentInterests: SegmentInterest[];
  editedInterests: ActivationScore[];
  interests: ActivationScore[];
  interestGroups: string[];
  interestTypes: string[];
  onSelectedSegment: (segment: string) => void;
  onSelectedInterestType: (interestType: string) => void;
  onOpenDrawer: (open: boolean) => void;
  onEditedInterests: (interests: any[]) => void;
  onAddInterest: (interest: ActivationScore) => void;
  onRemoveInterest: (interest: ActivationScore) => void;
  onSelectDeselectAll: (
    group: string,
    editedInterests: ActivationScore[],
    segmentInterests: SegmentInterest[] | undefined,
    interests: ActivationScore[] | undefined
  ) => void;
}

const InterestDrawer: React.FC<InterestDrawerProps> = ({
  openDrawer,
  segmentNames,
  selectedSegment,
  selectedInterestType,
  segmentInterestsGroups,
  segmentInterests,
  editedInterests,
  interests,
  interestGroups,
  interestTypes,
  onSelectedSegment,
  onSelectedInterestType,
  onOpenDrawer,
  onAddInterest,
  onRemoveInterest,
  onSelectDeselectAll,
}) => {
  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      onClose={() => onOpenDrawer(false)}
    >
      <Box
        sx={{
          width: 400,
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Add Interest
        </Typography>
        <Typography variant="body2" mb={2}>
          Select the interests you would like to add to your target audience.
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
          {segmentNames.length > 0 && (
            <FormControl variant="filled" fullWidth size="small">
              <InputLabel>Segments</InputLabel>
              <Select
                variant="filled"
                value={selectedSegment}
                onChange={(event) => onSelectedSegment(event.target.value)}
              >
                {segmentNames.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {selectedSegment && (
            <>
              <Box display="flex" flexDirection="column">
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Filters
                </Typography>
                <Grid display="flex" flexDirection="row" gap={1}>
                  {interestTypes.map((type, index) => (
                    <Grid key={index}>
                      <Chip
                        variant={'outlined'}
                        size="small"
                        label={type}
                        onClick={() => onSelectedInterestType(type)}
                        sx={{
                          borderColor: type
                            ? interestTypeColors[
                                type as keyof typeof interestTypeColors
                              ]
                            : 'default',
                          bgcolor:
                            selectedInterestType === type
                              ? interestTypeColors[
                                  type as keyof typeof interestTypeColors
                                ]
                              : 'default',
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              {segmentInterestsGroups.map((group) => {
                const filteredInterests = segmentInterests[0].interests.filter(
                  (interest) => interest.group === group
                );

                // dont display group if there are no interests
                if (filteredInterests.length === 0) return null;

                return (
                  <Box
                    key={group}
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      {group}
                    </Typography>
                    <Grid container gap={1}>
                      {filteredInterests.length > 0 &&
                        {
                          filteredInterests,
                        }?.filteredInterests.map((interest, index) => {
                          return (
                            <Grid key={index}>
                              <Chip
                                size="small"
                                label={interest.name}
                                color={
                                  editedInterests.find(
                                    (i) => i.name === interest.name
                                  )
                                    ? 'primary'
                                    : 'default'
                                }
                                onDelete={
                                  editedInterests.find(
                                    (i) => i.name === interest.name
                                  )
                                    ? () => onRemoveInterest(interest)
                                    : undefined
                                }
                                onClick={() => onAddInterest(interest)}
                                sx={{
                                  bgcolor: editedInterests.find(
                                    (i) => i.name === interest.name
                                  )
                                    ? interestTypeColors[
                                        interest.type as keyof typeof interestTypeColors
                                      ]
                                    : 'primary',
                                }}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                    <Grid container>
                      <Button
                        variant="text"
                        size="small"
                        onClick={() =>
                          onSelectDeselectAll(
                            group,
                            editedInterests,
                            segmentInterests,
                            undefined
                          )
                        }
                      >
                        {segmentInterests[0].interests
                          .filter((interest) => interest.group === group)
                          .every((interest) =>
                            editedInterests.some(
                              (i) => i.name === interest.name
                            )
                          )
                          ? 'Deselect All '
                          : 'Select All '}
                        {
                          segmentInterests[0].interests.filter(
                            (interest) => interest.group === group
                          ).length
                        }
                      </Button>
                    </Grid>
                  </Box>
                );
              })}
            </>
          )}
          {interestGroups.length > 0 && (
            <>
              <Box display="flex" flexDirection="column">
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Filters
                </Typography>
                <Grid display="flex" flexDirection="row" gap={1}>
                  {interestTypes.map((type, index) => (
                    <Grid key={index}>
                      <Chip
                        variant={'outlined'}
                        size="small"
                        label={type}
                        onClick={() => onSelectedInterestType(type)}
                        sx={{
                          borderColor: type
                            ? interestTypeColors[
                                type as keyof typeof interestTypeColors
                              ]
                            : 'default',
                          bgcolor:
                            selectedInterestType === type
                              ? interestTypeColors[
                                  type as keyof typeof interestTypeColors
                                ]
                              : 'default',
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>

              {interestGroups.map((group) => {
                const filteredInterests = interests.filter(
                  (interest) => interest.group === group
                );

                // dont display group if there are no interests
                if (filteredInterests.length === 0) return null;

                return (
                  <Box
                    key={group}
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      {group}
                    </Typography>
                    <Grid container gap={1}>
                      {filteredInterests.length > 0 &&
                        {
                          filteredInterests,
                        }?.filteredInterests.map((interest, index) => {
                          return (
                            <Grid key={index}>
                              <Chip
                                size="small"
                                label={interest.name}
                                color={
                                  editedInterests.find(
                                    (i) => i.name === interest.name
                                  )
                                    ? 'primary'
                                    : 'default'
                                }
                                onDelete={
                                  editedInterests.find(
                                    (i) => i.name === interest.name
                                  )
                                    ? () => onRemoveInterest(interest)
                                    : undefined
                                }
                                onClick={() => onAddInterest(interest)}
                                sx={{
                                  bgcolor: editedInterests.find(
                                    (i) => i.name === interest.name
                                  )
                                    ? interestTypeColors[
                                        interest.type as keyof typeof interestTypeColors
                                      ]
                                    : 'primary',
                                }}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                    <Grid container>
                      <Button
                        variant="text"
                        size="small"
                        onClick={() =>
                          onSelectDeselectAll(
                            group,
                            editedInterests,
                            undefined,
                            interests
                          )
                        }
                      >
                        {interests
                          .filter((interest) => interest.group === group)
                          .every((interest) =>
                            editedInterests.some(
                              (i) => i.name === interest.name
                            )
                          )
                          ? 'Deselect All '
                          : 'Select All '}
                        {
                          interests.filter(
                            (interest) => interest.group === group
                          ).length
                        }
                      </Button>
                    </Grid>
                  </Box>
                );
              })}
            </>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default InterestDrawer;
