import { AddCircle, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import { ActivationScore } from '@/types';

interface PeopleMatchSectionProps {
  topInterests: ActivationScore[];
  editedInterests: ActivationScore[];
  onOpenDrawer: () => void;
  onRemoveInterest: (name: string) => void;
  onSave: () => void;
}

const PeopleMatchSection: React.FC<PeopleMatchSectionProps> = ({
  topInterests,
  editedInterests,
  onOpenDrawer,
  onRemoveInterest,
  onSave,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);

  const handleSave = () => {
    onSave();
    setEditMode(false);
  };

  return (
    <Grid container display="flex" flexDirection="column">
      <Grid display="flex" flexDirection="row" gap={1} p={2}>
        <Grid xs={2}>
          <Box display="flex" gap={1} alignItems="center">
            <Typography variant="body1" fontWeight="bold">
              People Who Match
            </Typography>
            <Button
              variant="text"
              size="small"
              startIcon={<Edit />}
              onClick={() => setEditMode(true)}
            >
              Edit
            </Button>
          </Box>
        </Grid>
        <Grid xs={5} display="flex" flexDirection="column" gap={1}>
          {editMode ? (
            <Box display="flex" flexDirection="column" gap={1}>
              <Grid container display="flex" alignItems="center" gap={1}>
                {editedInterests.map((item) => (
                  <Grid key={item.name}>
                    <Chip
                      size="small"
                      label={item.name}
                      onDelete={() => onRemoveInterest(item.name)}
                    />
                  </Grid>
                ))}
                <Tooltip title="Add Interest" placement="bottom">
                  <IconButton size="small" onClick={onOpenDrawer}>
                    <AddCircle color="primary" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Box display="flex" gap={1}>
                <Button variant="contained" size="small" onClick={handleSave}>
                  Save
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setEditMode(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          ) : (
            <Typography variant="body1">
              {topInterests.map((item) => item.name).join(', ')}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PeopleMatchSection;
