import { IAudience, IQueryGroup } from '@/interfaces';

import { ProgressStep } from '@/enums';
import { SectionType } from '@/types';
import { Step } from '@/components/organisms/progress-modal';

export const INSIGHT_MODE_LIMIT_DAYS = 365;
export const QUERY_GROUP_STEP_FORM_HEIGHT = '100%';
export const QUERY_GROUP_STEP_HEIGHT = 550;
export const SOCIAL_COUNT = 50000;

export const defaultAudience: IAudience = {
  query_groups: [],
  operator: 'AND',
  name: '',
  country: 1,
  country_iso: 'GB',
  country_name: 'United Kingdom',
  age: ['Any'],
  gender: ['Any'],
  shared: false,
  shared_with: [],
};

export const defaultQueryGroup: IQueryGroup = {
  operator: 'ANY',
  limiter: 1,
  stars: [],
  industry: null,
  interests: [],
  query_mode: 'interest',
  query: [],
  summary: null,
};

export const GENDERS_MAP = {
  Male: 'Males',
  Female: 'Females',
  Any: 'People',
};

export const operators = ['AND', 'OR'];

export const interestFilterOperators = ['AND', 'OR'];

export const starsFilterOperators: string[] = [
  'ANY',
  'ALL',
  'MORE THAN',
  'LESS THAN',
];

export const interestQueryOperators = ['ANY', 'MORE THAN', 'LESS THAN'];

export const queryModes = [
  {
    name: 'interest',
    mode: 'Interest',
    description: 'Build an audience by using your selected interests',
  },
  {
    name: 'star',
    mode: 'Social Account',
    description: 'Build an audience by selecting social accounts',
  },
];

export const ageOptions = ['Any', '18-24', '25-34', '35-44', '45+'];
export const genderOptions = ['Any', 'Male', 'Female'];

export const audienceBuildProgressSteps: Step[] = [
  {
    step: ProgressStep.BuildingAudience,
    description: 'Building Audience',
    hasError: false,
    isCompleted: false,
  },
  {
    step: ProgressStep.GettingInsights,
    description: 'Getting insights',
    hasError: false,
    isCompleted: false,
  },
];

export const segmentBuildProgressSteps: Step[] = [
  {
    step: ProgressStep.PreparingSegment,
    description: 'Preparing segment',
    hasError: false,
    isCompleted: false,
  },
  {
    step: ProgressStep.BuildingSegment,
    description: 'Building segment',
    hasError: false,
    isCompleted: false,
  },
  {
    step: ProgressStep.GettingInsights,
    description: 'Getting insights',
    hasError: false,
    isCompleted: false,
  },
];

export const audienceRebuildProgressSteps: Step[] = [
  {
    step: ProgressStep.BuildingAudience,
    description: 'Building audience',
    hasError: false,
    isCompleted: false,
  },
  {
    step: ProgressStep.PreparingSegment,
    description: 'Preparing segment',
    hasError: false,
    isCompleted: false,
  },
  {
    step: ProgressStep.BuildingSegment,
    description: 'Building segment',
    hasError: false,
    isCompleted: false,
  },
  {
    step: ProgressStep.GettingInsights,
    description: 'Getting insights',
    hasError: false,
    isCompleted: false,
  },
];

export const insightViews = ['Summary', 'Detailed'];
export const insightViewsSegment = ['Summary', 'Detailed', 'Comparison'];

export const segmentColors = [
  '#b9204b',
  '#a9422f',
  '#008080',
  '#643cc1',
  '#b2842d',
  '#319234',
];

export const activationSections: { [key: string]: SectionType } = {
  Meta: {
    demographics: ['Demographics', 'Gender', 'Age Range'],
    country: ['Country'],
    peopleWhoMatch: ['People who match'],
    andAlsoMatch: ['And also match'],
  },
  Google: {
    demographics: ['Demographics', 'Gender', 'Age Range'],
    country: ['Country'],
    googleAudiences: ['Google Audiences'],
    interests: ['Interests'],
  },
};

export const interestTypeColors = {
  brand: '#3caf69',
  influencer: '#EA673B',
  media: '#ffb200',
  interest: '#F3546D',
};
