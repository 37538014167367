import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import { DefaultImage, SegmentDemogs } from '@/components';
import { PeopleAlt, Person } from '@mui/icons-material';

import { IAudienceSegment } from '@/interfaces';
import React from 'react';
import { abbreviateNumber } from '@/helpers';
import { interestIcon } from '@/constants/icon-map';

type SegmentAudienceCardProps = {
  segment: IAudienceSegment;
};

export const SegmentAudienceCard: React.FC<SegmentAudienceCardProps> = ({
  segment,
}) => {
  const topAge = segment.summary.demographics
    .filter((score) => score.type === 'Age')
    .reduce((prev, current) => {
      return prev.index > current.index ? prev : current;
    });

  const topGender = segment.summary.demographics
    .filter((score) => score.type === 'Gender')
    .reduce((prev, current) => {
      return prev.penetration > current.penetration ? prev : current;
    });

  const topInterests = [...segment.score.interests]
    .sort((a, b) => b.followers - a.followers)
    .slice(0, 3);

  const topBrands = [...segment.score.brands]
    .sort((a, b) => b.followers - a.followers)
    .slice(0, 3);

  const topMedia = [...segment.score.media]
    .sort((a, b) => b.followers - a.followers)
    .slice(0, 3);

  return (
    <Card
      sx={{
        backgroundColor: 'transparent',
        height: '100%',
        minWidth: '280px',
        width: '350px',
        border: `2px solid primary.main`,
      }}
    >
      <Box px={2} py={1}>
        <Typography variant="h6">Audience</Typography>
      </Box>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          py: 2,
          px: 2,
          gap: 2,
        }}
      >
        <Typography variant="body1">Summary</Typography>
        <Box
          display={'flex'}
          flexDirection={'row'}
          height="88px"
          gap={3}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Stack gap={1} alignItems="center">
            <Typography variant="caption" color={'text.secondary'}>
              Audience Size
            </Typography>
            <Box height={60}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                gap={1}
              >
                <Person fontSize={'small'} />
                <Typography color={'text.secondary'}>
                  {abbreviateNumber(segment.summary.audience_size.social_size)}
                </Typography>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                gap={1}
              >
                <PeopleAlt fontSize={'small'} />
                <Typography color={'text.secondary'}>
                  {abbreviateNumber(
                    segment.summary.audience_size.modelled_size
                  )}
                </Typography>
              </Box>
            </Box>
          </Stack>
          <SegmentDemogs age={topAge} gender={topGender} />
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Typography variant="body1">Top Interests</Typography>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-around'}
          >
            {topInterests.map((interest, index) => (
              <Tooltip placement="bottom" title={interest.name} key={index}>
                <Stack key={index} spacing={1} alignItems="center" width={53}>
                  <SvgIcon
                    component={interestIcon(interest.name).ReactComponent}
                    inheritViewBox
                    sx={{ fontSize: '30px' }}
                  />
                  <Typography
                    variant="caption"
                    align="center"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {interest.name.split(' / ')[0]}
                  </Typography>
                </Stack>
              </Tooltip>
            ))}
          </Box>

          <Typography variant="body1">Top Brands</Typography>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-around'}
          >
            {topBrands.map((star, index) => (
              <Stack key={index} spacing={1} alignItems="center">
                <Tooltip key={star.id} placement="bottom" title={star.name}>
                  <Avatar
                    src={star.image_url}
                    alt={star.name}
                    variant="square"
                    sx={{
                      width: 53,
                      height: 53,
                      borderRadius: '6px',
                      color: '#fff',
                    }}
                  >
                    <DefaultImage size={53} name={star.name} />
                  </Avatar>
                </Tooltip>
              </Stack>
            ))}
          </Box>

          <Typography variant="body1">Top Media</Typography>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-around'}
          >
            {topMedia.map((star, index) => (
              <Stack key={index} spacing={1} alignItems="center">
                <Tooltip key={star.id} placement="bottom" title={star.name}>
                  <Avatar
                    src={star.image_url}
                    alt={star.name}
                    variant="square"
                    sx={{
                      width: 53,
                      height: 53,
                      borderRadius: '6px',
                      color: '#fff',
                    }}
                  >
                    <DefaultImage size={53} name={star.name} />
                  </Avatar>
                </Tooltip>
              </Stack>
            ))}
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Box height={32} />
      </CardActions>
    </Card>
  );
};
