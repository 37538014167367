import { Grid, Typography } from '@mui/material';

import React from 'react';

interface CountrySectionProps {
  countryName: string;
}

const CountrySection: React.FC<CountrySectionProps> = ({ countryName }) => {
  return (
    <Grid container display="flex" flexDirection="column">
      <Grid display="flex" flexDirection="row" gap={1} p={2}>
        <Grid xs={2}>
          <Typography variant="body1" fontWeight="bold">
            Country
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Typography variant="body1">{countryName}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CountrySection;
