import { useCallback, useEffect, useState } from 'react';

import { ActivationApiResponse } from '@/interfaces';
import { getAudienceActivation } from '@/api/audience';
import { useAuth0 } from '@auth0/auth0-react';

export const useAudienceActivation = (id: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const [activation, setActivation] = useState<ActivationApiResponse | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const response = await getAudienceActivation(accessToken, id);
      setActivation(response.data);
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  }, [id, getAccessTokenSilently]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { activation, loading, error };
};
